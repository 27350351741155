//校验数字
const checkNumber = (rule, value, cb) => {
  const regMobile = /^\d+$/
  if (regMobile.test(value)) {
    return cb()
  }
  cb(new Error('请输入合法的数字'))
}

//校验规则
const rules = {
  name: [{
    required: true,
    message: '公司名称不能为空',
    trigger: 'blur'
  }],
  contacts: [{
    required: true,
    message: '公司法人不能为空',
    trigger: 'blur'
  }],
  phone: [
    {
      required: true,
      message: '请输入手机号',
      trigger: 'blur'
    },
    {
      min: 11,
      max: 11,
      message: '请输入合法的手机号',
      trigger: 'blur'
    },
    {
      validator: checkNumber,
      trigger: 'blur'
    }
  ],
  phone2: [
    {
      required: true,
      message: '请输入手机号',
      trigger: 'blur'
    },
    {
      min: 11,
      max: 11,
      message: '请输入合法的手机号',
      trigger: 'blur'
    },
    {
      validator: checkNumber,
      trigger: 'blur'
    }
  ],
  contactPerson: [{
    required: true,
    message: '公司业务联系人不能为空',
    trigger: 'blur'
  }],
  contactPerson2: [{
    required: true,
    message: '公司主管联系人不能为空',
    trigger: 'blur'
  }],
  mailbox: [{
    required: true,
    message: '公司邮箱不能为空',
    trigger: 'blur'
  }],
  address: [{
    required: true,
    message: '公司地址不能为空',
    trigger: 'blur'
  }],
  socialCode: [{
    required: true,
    message: '统一社会信用代码不能为空',
    trigger: 'blur'
  }],
  capital: [
    {
      required: true,
      message: '注册资本不能为空',
      trigger: 'blur'
    },
    {
      validator: checkNumber,
      trigger: 'blur'
    }
  ],
  addvalidity: [{
    required: true,
    message: '营业执照有效期不能为空',
    trigger: 'blur'
  }],
  editvalidity: [{
    required: true,
    message: '营业执照有效期不能为空',
    trigger: 'blur'
  }],
  bank: [{
    required: true,
    message: '银行开户户头不能为空',
    trigger: 'blur'
  }],
  account: [{
    required: true,
    message: '银行账号不能为空',
    trigger: 'blur'
  }],
  fullAccount: [{
    required: true,
    message: '完整开户行名称不能为空',
    trigger: 'blur'
  }],
  k6: [{
    required: true,
    message: '厂房总面积不能为空',
    requireds: ['MANUFACTURER'],
    trigger: 'blur'
  }],
  k9: [{
    required: true,
    message: '设备清单不能为空',
    requireds: ['MANUFACTURER'],
    trigger: 'blur'
  }],
  k1: [{
    required: true,
    message: '管理人员数不能为空',
    trigger: 'blur'
  }],
  category1: [{
    required: true,
    message: '经营类目/大类不能为空',
    trigger: 'blur'
  }],
  category2: [{
    required: true,
    message: '内容不能为空',
    trigger: 'blur'
  }],
  category3: [{
    required: true,
    message: '经营类目/中类不能为空',
    trigger: 'blur'
  }],
  enumType: [{
    required: true,
    message: '供应商类型不能为空',
    trigger: 'blur'
  }]
}

//用户登录验证规则
const rulesLogin = {
  username: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
    { min: 11, max: 11, message: '请输入合法的手机号', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入登录密码', trigger: 'blur' },
    { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
  ],
  code: [
    { required: true, message: '验证码错误', trigger: 'blur' },
    { min: 4, max: 4, message: '验证码错误', trigger: 'blur' }
  ]
}

//导出规则
export {
  rules,
  rulesLogin
}
