<template>
  <div class="body-box">
    <div class="nav-box">
      <div class="w">
        <div class="lf">
          <div>欢迎来到沃莱迪金属招标与采购</div>
          <div class="tips">温馨提示：请使用 <a href="https://dldir1.qq.com/invc/tt/QQBrowser_Setup_qb10.exe">QQ浏览器</a><a
            href="https://dldir1.qq.com/invc/tt/QQBrowser_Setup_qb10.exe">下载QQ浏览器</a></div>
        </div>
        <div class="rt" v-if="loginStatus">
          <ul>
            <li><span>欢迎&nbsp;</span><span style="color: #000000">{{ loginText }}</span></li>
            <li>|</li>
            <!--<li><a style="text-decoration:none;" :href="adminUrl"><span style="color: #246bef">进入管理后台</span></a></li>-->
            <li>
              <el-link :class="colorVal ? 'btn' : 'btn2'" @click="jumpAdmin">点击此处进入后台报价</el-link>
            </li>
            <li>|</li>
            <li><span class="el-icon-switch-button" @click="logout">&nbsp;退出</span></li>
          </ul>
        </div>
      </div>
    </div>
    <!--导航盒子-->
    <nav-box></nav-box>

    <!--banner盒子-->
    <div class="banner-box">
      <img src="@/assets/images/banner.png" alt="">

      <!--登录盒子-->
      <div class="login-box" v-show="display">
        <el-row style="padding: 20px 20px 10px 20px;">
          <el-col :span="24">
            <div class="tips">温馨提示：请使用 <a href="https://dldir1.qq.com/invc/tt/QQBrowser_Setup_qb10.exe">QQ浏览器</a></div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content">供应商登录</div>
          </el-col>

          <el-col :span="24">
            <el-form ref="form" :model="form" :rules="loginFormRules" label-width="70px">
              <el-row>
                <el-col :span="24">
                  <el-form-item prop="username" label="账号">
                    <el-input @keyup.native.enter="login" v-model.trim="form.username"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item prop="password" label="密码">
                    <el-input @keyup.native.enter="login" v-model.trim="form.password" type="password"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="24" class="Verification">
                  <el-form-item prop="code" label="验证码" class="verify">
                    <el-input @keyup.native.enter="login" v-model.trim="form.code" placeholder="请输入验证码"></el-input>
                    <span class="pic"><img :src="this.picCode" alt="" @click="variation"/></span>
                  </el-form-item>
                </el-col>

                <el-col :span="24" class="login-bottom">
                  <el-button @click="login">登录</el-button>
                  <el-button @click="register">注册</el-button>
                  <el-button @click="forgetPassword">忘记密码</el-button>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--图标盒子-->
    <div class="w">
      <!--图标-->
      <div class="icon">
        <div class="lf">
          <span class="iconfont">&#xe605;</span>
          <p>招标公告</p>
        </div>
        <div class="lf">
          <span class="iconfont">&#xe605;</span>
          <p>公司公告</p>
        </div>
      </div>

      <!--列表页-->
      <div class="list">
        <div class="lf">
          <ul>
            <li @click="toNoticeDetail(item.id)" v-for="(item,index) in lists" :key="index">
              <span>{{ item.title }}</span>
              <i>{{ item.createDateTime | dateFormat }}</i>
            </li>
            <li class="look" v-if="lists.length>3"><i @click="viewMoreNotices">查看更多</i></li>
          </ul>
        </div>
        <div class="lf">
          <ul>
            <li class="look" @click="notification">
              <span>公司公告</span>
              <i @click="notification">查看更多</i>
            </li>
            <li @click="notification" style="cursor: pointer">
              <span>供应商注册及操作通知</span>
              <i>2020-06-24</i>
            </li>
          </ul>
        </div>
      </div>

      <div class="Announcement">
        <ul>
          <li class="look" @click="notification">
            <span>法律公告</span>
            <i @click="notification">查看更多</i>
          </li>
          <li @click="notification" style="cursor: pointer">
            <span>供应商注册及操作通知</span>
            <i>2020-06-24</i>
          </li>
        </ul>
      </div>

      <div class="Delineation"></div>

      <!--底部盒子-->
      <qq-footer></qq-footer>
    </div>
    <el-dialog :visible.sync="open" width="50%" :before-close="handleClose">
      <!-- <h4>反商业贿赂承诺书</h4> -->
      <div class="content-box">
        <div v-html="this.briberyList.content" id="scroll"></div>
        <div class="agree">
          <template>
            <el-checkbox v-model="checked">同意《反商业贿赂承诺书》</el-checkbox>
          </template>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="read" v-if="!this.checked">需要阅读完成并同意反商业贿赂承诺书才能继续注册!</div>
        <el-button @click="open = false">返回</el-button>
        <el-button
          type="primary"
          :disabled="!this.checked"
          @click="openRegister"
        >继续注册</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import NavBox from '@/components/common/navBox'
import QqFooter from '@/components/common/qqFooter'
import {rulesLogin} from '@/js/rules'
import {commitment} from '@/js/staticClass'

export default {
  components: {
    QqFooter,
    NavBox
  },
  data() {
    return {
      lists: [],
      loginText: '请登录',
      display: true,
      form: {},
      open: false,
      loginStatus: false,
      checked: false,
      adminUrl: '',
      loginFormRules: rulesLogin,
      picCode: '',
      briberyList: {content: commitment},
      queryInfo: {
        current: 1,
        size: 3,
        keyword: ''
      },
      colorVal: false,
    }
  },
  mounted() {
  },

  watch: {
    colorVal(newVal) {
      this.colorVal = newVal
    }
  },

  created() {
    this.getColor()
    const token = Cookies.get('token') || sessionStorage.getItem('token')
    if (token) {
      this.loginByToken()
    }
    // 配置跳转后端地址
    const protocol = window.location.protocol
    const host = window.location.host
    this.adminUrl = `${protocol}//${host}/manage-ui/#/transitPage?token=${token}`
    this.getNotices()
    // 判断是否已登录
    if (token) {
      this.loginStatus = true
      this.display = false
    } else {
      this.getClod()
    }
  },
  methods: {
    jumpAdmin() {
      const token = Cookies.get('token') || sessionStorage.getItem('token')
      if (token) {
        this.loginByToken()
      }
      // 配置跳转后端地址
      const protocol = window.location.protocol
      const host = window.location.host
      this.adminUrl = `${protocol}//${host}/manage-ui/#/transitPage?token=${token}`
      window.location.href = this.adminUrl
    },

    /*    getJump() {
          const protocol = window.location.protocol
          const host = window.location.host
          this.adminUrl = protocol + '//' + host + '/manage-ui/#/welcome'
          window.history.href = this.adminUrl
          //window.open(this.adminUrl)
          // window.location.href = 'http://localhost:8080/#/welcome'
        },*/

    getColor() {
      setInterval(() => {
        this.colorVal = !this.colorVal
      }, 500)
    },

    toNoticeDetail(id) {
      this.$router.push({
        path: '/noticeDetail',
        query: {id}
      })
    },

    viewMoreNotices() {
      this.$router.push({
        path: '/notice'
      })
    },

    //忘记密码
    forgetPassword() {
      this.$router.push('/forgetPassword')
    },

    getNotices() {
      this.axios.get(`${this.getaway.oa}srm/notices/getAll`, {
        params: {
          current: 1,
          size: 5,
          keyword: ''
        }
      }).then((res) => {
        this.lists = res.data.records
      })
    },

    //获取验证码
    async getClod() {
      const sn = new Date().getTime()
      sessionStorage.setItem('sn', sn)
      this.picCode = await `${this.getaway.user}auth/genVerificationCode?from=WEB&sn=${sn}`
    },

    //刷新验证码
    variation() {
      this.picCode = this.picCode + '&k=' + Math.random()
    },
    //打开承诺书
    register() {
      this.open = true
    },

    //关闭承诺书
    handleClose() {
      this.open = false
    },

    // 继续注册
    openRegister() {
      this.handleClose()
      this.$router.push('/register')
    },

    //退出登录
    async logout() {
      this.$confirm('确定退出吗', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('logout', flag => {
          if (flag) {
            this.display = true
            this.loginText = '请登录'
            this.loginStatus = false
            this.$router.go(0)
          } else {
            this.$message.error('退出失败!')
          }
        })
      }).catch(() => {
      })
    },

    //解析登录信息
    parseLogin() {
      this.display = false
      this.loginStatus = true
      this.loginText = this.$store.state.name
      const enumType = this.$store.state.userInfo.enumType
      if (enumType === 'SUPPLIER' && !this.$store.state.srmSupplierId) {
        this.$confirm('您的供应商注册信息未完成，是否继续?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          callback: async (action) => {
            if (action === 'confirm') {
              sessionStorage.setItem('usreId', this.$store.state.userId)
              await this.$router.push({
                  path: '/mation',
                  query: {userId: this.$store.state.userId}
                }
              )
            } else {
              return false
            }
          }
        })
      }
    },

    //根据token登录
    async loginByToken() {
      if (await this.$store.dispatch('loginByToken')) {
        this.parseLogin();
      }
    },

    //账号密码登录
    async login() {
      await this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (await this.$store.dispatch('login', this.form)) {
            this.parseLogin();
          } else {
            this.variation()
          }
        } else {
          return false
        }
      })
    },

    //查看公告
    look() {
      this.$router.push('/notice')
    },

    notification() {
      this.$router.push('/notification')
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  border: none;
  color: #FDAA42;

  &:hover {
    border: none;
    box-shadow: none;
  }
}

.btn2 {
  border: none;
  color: #FA264E;

  &:hover {
    border: none;
    box-shadow: none;
  }
}

ul, li div {
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav-box {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #868686;
  border-bottom: 1px solid #F1F4FA;

  .lf {
    float: left;
  }

  .rt {
    float: right;

    ul {
      li {
        float: left;
        padding: 0 10px;
        height: 20px;

        &:hover {
          cursor: pointer;
          //color: #0086b3;
        }

        &:nth-child(1) {
          cursor: text;
        }

        &:nth-child(2n) {
          color: #F2F4FA;
        }
      }
    }
  }
}

/deep/ .el-col {
  .el-input {
    width: 90%;

    .el-input__inner {
      height: 36px;
      line-height: 36px;
    }
  }
}

.grid-content {
  margin: 16px 14px;
  padding-left: 16px;
}

/deep/ .verify {
  position: relative;

  .el-input__inner {
    width: 60%;
  }

  .pic {
    position: absolute;
    top: 1px;
    right: 38px;
    width: 120px;
    height: 34px;
    border: 1px solid #dcdfe6;
  }
}

.login-bottom {
  text-align: center;

  .el-button {
    background-color: #fff;
    color: #666666;
    border-radius: 20px;
    padding: 2px 26px;
    border: 1px solid #d8e1f2;
    margin-right: 8px;

    &:hover {
      background-color: #fff;
      color: #3150e9;
      border: 1px solid transparent;
      box-shadow: 0px 0px 10px 4px rgba(25, 54, 196, 0.1);
    }
  }
}

.w {
  width: 1200px;
  margin: 0 auto;
}

/deep/ .el-button--primary.is-disabled {
  background: #ccc;
  border: 1px solid #DCDFE6;

  &:hover {
    background: #ccc;
    border: 1px solid transparent;
    box-shadow: none;
  }
}

/deep/ .el-button--primary {
  border: 1px solid #DCDFE6;
}

.lf {
  div {
    float: left;
  }
}

.tips {
  float: left;
  margin-left: 20px;
  color: #dd820c;
  font-size: 12px;

  a {
    color: #dd820c;
    margin: 0 5px;
  }
}

.icon {
  margin-top: 70px;
  display: flex;
  height: 185px;
  text-align: center;

  .lf {
    flex: 1;
    height: 20px;
  }

  .rt {
    flex: 1;
    height: 20px;
  }

  span {
    display: block;
    margin: 0 auto;
    border-radius: 60px;
    width: 116px;
    height: 116px;
    font-size: 48px;
    line-height: 116px;
    background-color: #f8f9fc;
    color: #c5d1e9;

    &:hover {
      background-color: #246bef;
      cursor: pointer;
      color: #ffffff;
    }
  }
}

.list {
  height: 320px;
  display: flex;

  .lf, .rt {
    cursor: pointer;
    box-sizing: border-box;
    flex: 1;
    height: 320px;
    margin-right: 22px;
    border: 1px solid #dee5f7;
    border-radius: 10px;
    padding: 14px 18px 0 18px;

    li {
      height: 46px;
      line-height: 46px;
      font-size: 14px;
      color: #333333;
      cursor: pointer;

      span {
        float: left;
      }

      i {
        font-style: normal;
        float: right;
        font-size: 14px;
        color: #333333;
        text-align: right;
      }
    }

    .look {
      margin-top: 20px;
      cursor: pointer;

      i {
        color: #2759ec;
      }
    }
  }
}

.Delineation {
  border-bottom: 1px dashed #c5d1e9;
  margin-top: 42px;
}

.Announcement {
  margin-top: 44px;
  height: 146px;
  border: 1px solid #dee5f7;
  box-sizing: border-box;
  padding: 14px 18px 0 18px;

  li {
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    color: #333333;
    cursor: pointer;

    span {
      float: left;
    }

    i {
      font-style: normal;
      float: right;
      font-size: 14px;
      color: #333333;
      text-align: right;
    }
  }

  .look {
    cursor: pointer;

    i {
      color: #2759ec;
    }

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.inform {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;

  a {
    text-decoration: none;
    font-size: 14px;
  }

  .el-button {
    border: none;
    padding: 0;

    &:hover {
      border: none;
      box-shadow: none;
    }
  }
}

.forget {
  margin-left: 15px;
  font-size: 12px;
  color: #584f4f;
}

.banner-box {
  width: 100%;
  height: 600px;
  position: relative;

  > img {
    width: 100%;
    height: 100%;
  }
}

.body-box {
  width: 100%;
  height: 100%;
}

.login-box {
  position: absolute;
  top: 140px;
  right: 14%;
  width: 500px;
  height: 356px;
  background-color: #fff;;
}

/deep/ .el-dialog__footer {
  text-align: center;
}

/deep/ .el-dialog__body {
  height: 300px;
  overflow: auto;
}

/deep/ .el-dialog {
  max-height: 500px;

  .agree {
    text-align: center;
  }

  .read {
    color: #ee9900;
    font-size: 12px;
    margin-bottom: 5px;
  }

  span {
    color: #000000;
  }
}

.content-box {
  height: 300px;
  overflow: auto;
}

dd,
dt {
  line-height: 1.6em;
}
</style>
